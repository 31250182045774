var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"page header-container"},[_c('Logo',{attrs:{"link":_vm.useGetLocalizedPath('')}}),_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"header-menu"},[(_vm.user && _vm.user.account_state === _vm.UserRoleState.Vendor)?_c('router-link',{staticClass:"menu-link",attrs:{"to":_vm.useGetLocalizedPath(
              ((_vm.routes.booking.BOOKINGS) + "/" + (_vm.routes.booking.VENDOR))
            ),"data-gtm":"header_my_dashboard_tab"}},[_vm._v(" "+_vm._s(_vm.$t('header.link_label_dashboard'))+" ")]):_vm._e(),(_vm.user && _vm.user.account_state === _vm.UserRoleState.Vendor)?_c('router-link',{staticClass:"menu-link",attrs:{"to":_vm.useGetLocalizedPath(
              ((_vm.routes.booking.BOOKINGS) + "/" + (_vm.routes.booking.ALL))
            ),"data-gtm":"header_my_bookings_tab"}},[_vm._v(" "+_vm._s(_vm.$t('header.link_label_bookings'))+" ")]):_vm._e(),(_vm.user && _vm.user.account_state === _vm.UserRoleState.Vendor)?_c('router-link',{staticClass:"menu-link",attrs:{"to":_vm.useGetLocalizedPath('vendor/spaces'),"data-gtm":"header_my_spaces_tab"}},[_vm._v(" "+_vm._s(_vm.$t('header.link_label_spaces'))+" ")]):_vm._e(),(_vm.user && _vm.user.account_state === _vm.UserRoleState.Vendor)?_c('router-link',{staticClass:"menu-link",attrs:{"to":_vm.useGetLocalizedPath(_vm.routes.services.SERVICES),"data-gtm":"header_my_services_tab"}},[_vm._v(" "+_vm._s(_vm.$t('header.link_label_services'))+" ")]):_vm._e()],1),(
          _vm.header &&
          _vm.mq &&
          !_vm.mq.phone &&
          (!_vm.user || _vm.user.account_state === _vm.UserRoleState.Booker)
        )?_c(_vm.header,{tag:"component"}):_vm._e()],1),_c('div',{staticClass:"header-right"},[_c('div',{staticClass:"menu-link language-link",attrs:{"href":"/","data-gtm":"header_languages_dropdown"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleOpenLanguageList.apply(null, arguments)}}},[_c('img',{staticClass:"language-icon",attrs:{"src":("/img/icons/" + (_vm.$i18n.locale || 'nl') + "_icon.png"),"alt":"lang_icon","loading":"lazy","width":"30","height":"20"}}),(_vm.languageListOpen)?_c('DropdownList',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeLanguageList),expression:"closeLanguageList"}],staticClass:"language-dropdown",attrs:{"options":_vm.languagesArray},on:{"input":_vm.onSelectLanguage}}):_vm._e()],1),(!_vm.user)?_c('section',{staticClass:"header-items"},[_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath('vendor')}},[_c('div',{staticClass:"button-marnix clear",attrs:{"data-gtm":"unauthorized_become_a_host_button"}},[_vm._v(" "+_vm._s(_vm.$t('header.link_label_host'))+" ")])]),_c('router-link',{staticStyle:{"margin-right":"10px"},attrs:{"to":{
            query: Object.assign({}, _vm.$route.query, {authPopup: 'sign-up'})
          }}},[_c('WMButton',{attrs:{"data-gtm":"header_signup_button","small":true},on:{"click":_vm.onLoginClicked}},[_vm._v(_vm._s(_vm.$t('header.button_label_signup'))+" ")])],1),_c('router-link',{attrs:{"to":{
            query: Object.assign({}, _vm.$route.query, {authPopup: 'login'})
          }}},[_c('WMButton',{staticClass:"button-marnix clear",attrs:{"small":"","data-gtm":"header_login_button"},on:{"click":_vm.onLoginClicked}},[_vm._v(" "+_vm._s(_vm.$t('header.button_label_login'))+" ")])],1)],1):_vm._e(),(_vm.user && _vm.user.account_state === _vm.UserRoleState.Booker)?_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath('vendor')}},[_c('div',{staticClass:"button-marnix clear",attrs:{"data-gtm":"authorized_become_a_host_button"}},[_vm._v(" "+_vm._s(_vm.$t('header.link_label_host'))+" ")])]):_vm._e(),(_vm.user)?_c('section',{staticClass:"header-items"},[(_vm.user.account_state === _vm.UserRoleState.Booker)?_c('router-link',{staticClass:"menu-link icon menu-bookings-link",attrs:{"to":_vm.useGetLocalizedPath(
              ((_vm.routes.booking.BOOKINGS) + "/" + (_vm.routes.booking.ALL))
            ),"data-gtm":"header_my_bookings_tab"}},[_c('div',{staticClass:"menu-link__bookings"},[_c('div',[_c('i',{staticClass:"fa-solid fa-handshake fa-sm"})]),_c('span',[_vm._v(_vm._s(_vm.$t('header.link_label_bookings')))])])]):_vm._e(),_c('router-link',{staticClass:"menu-link icon",attrs:{"to":_vm.useGetLocalizedPath(_vm.routes.chat.CHAT),"data-gtm":"header_my_chat_messages_tab"}},[_c('ChatUnreadProvider',{staticClass:"menu-link__messages",on:{"has-new-messages":function($event){return _vm.onNewMessages($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var unread = ref.unread;
return [_c('div',[_c('i',{class:("fa-solid fa-comment fa-sm" + (!!unread ? ' fa-beat' : '')),style:(("position: relative" + (!!unread ? '; --fa-animation-duration: 2s;' : '')))},[(!!unread)?_c('span',{staticClass:"red-dot"},[_vm._v(_vm._s(unread))]):_vm._e()])]),_c('span',[_vm._v(_vm._s(_vm.$t('header.link_label_messages')))])]}}],null,false,1938531226)})],1),(_vm.user)?_c('router-link',{staticClass:"menu-link icon",attrs:{"to":_vm.useGetLocalizedPath(_vm.routes.favorites.FAVORITES),"data-gtm":"header_favorites_tab"}},[_c('div',{staticClass:"menu-link__favorites"},[_c('div',[_c('i',{staticClass:"fa-sharp fa-solid fa-heart"})]),_c('span',[_vm._v(_vm._s(_vm.$t('header.link_label_favorites')))])])]):_vm._e(),_c('router-link',{staticClass:"menu-link icon",attrs:{"to":_vm.useGetLocalizedPath(_vm.routes.notification.NOTIFICATIONS),"data-gtm":"header_my_notifications_tab"}},[_c('NotificationsCounterProvider',{staticClass:"menu-link__notifications",on:{"has-new-notifications":function($event){return _vm.onNewNotifications($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var counter = ref.counter;
return [_c('div',[_c('i',{class:("fa-solid fa-bell fa-sm" + (!!counter ? ' fa-beat' : '')),style:(("position: relative" + (!!counter ? '; --fa-animation-duration: 2s;' : '')))},[(!!counter)?_c('span',{staticClass:"red-dot"},[_vm._v(_vm._s(counter))]):_vm._e()])]),_c('span',[_vm._v(_vm._s(_vm.$t('header.link_label_notifications')))])]}}],null,false,1724317173)})],1),(_vm.user.is_admin)?_c('router-link',{staticClass:"menu-link icon",attrs:{"to":_vm.useGetLocalizedPath(_vm.routes.admin.ADMIN),"data-gtm":"header_admin_tab"}},[_c('div',{staticClass:"menu-link__notifications"},[_c('div',[_c('i',{staticClass:"fa-solid fa-screwdriver-wrench fa-sm"})]),_c('span',[_vm._v(_vm._s(_vm.$t('header.link_label_admin')))])])]):_vm._e()],1):_vm._e(),_c('BurgerMenu',{attrs:{"notifications-counter":_vm.notificationsCounter,"chat-messages-counter":_vm.chatMessagesCounter,"on-full-screen":_vm.mq && !_vm.mq.phone},on:{"click":function($event){_vm.popupMenuVisible = true}}})],1),_c('MenuPopup',{attrs:{"notifications-counter":_vm.notificationsCounter,"chat-messages-counter":_vm.chatMessagesCounter,"on-full-screen":_vm.mq && !_vm.mq.phone},model:{value:(_vm.popupMenuVisible),callback:function ($$v) {_vm.popupMenuVisible=$$v},expression:"popupMenuVisible"}})],1),(_vm.breadcrumb && _vm.mq && !_vm.mq.phone)?_c(_vm.breadcrumb,{tag:"component"}):_vm._e(),(_vm.mq && _vm.mq.phone && _vm.breadcrumb)?_c(_vm.breadcrumb,{tag:"component"}):_vm._e(),(_vm.mq && _vm.mq.phone && _vm.header)?_c(_vm.header,{tag:"component"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }