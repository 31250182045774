import { defineComponent, ref, onMounted } from '@vue/composition-api';
export default defineComponent({
    props: {
        options: {
            type: Array,
            default: () => []
        },
        defaultOption: {
            type: String,
            default: ''
        },
        isDefaultScrollEnabled: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const defaultOptionRef = ref(null);
        const selectOption = (value) => {
            emit('input', value);
        };
        onMounted(() => {
            if (defaultOptionRef.value && props.isDefaultScrollEnabled) {
                defaultOptionRef.value[0].scrollIntoView();
            }
        });
        return {
            defaultOptionRef,
            selectOption
        };
    }
});
