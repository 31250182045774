import { defineComponent } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { AccountState } from '@/generated-types/graphql.types';
export default defineComponent({
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const continueWithThisBrowser = () => {
            if (props.user?.account_state === AccountState.Vendor &&
                !props.user?.organisation_id) {
                globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                return;
            }
            globalThis.$router.replace({ query: { authPopup: undefined } });
            globalThis.$router.go(); // Reload the page
        };
        return {
            continueWithThisBrowser
        };
    }
});
