import { defineComponent, ref, watch } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import Tip from '@/uikit/Tip.vue';
import { serverValidationMixin, server, validationRules, useValidation, prefixError } from '@/util/validation';
import { useResendTimer } from '@/util/hooks';
import { timeZero } from '@/util/filters';
export default defineComponent({
    components: {
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        emailConfirmationForm: {
            email: {
                required,
                email,
                _email: server('_email')
            }
        }
    },
    props: {
        signUpData: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root } = context;
        const emailConfirmationForm = ref({
            email: props.signUpData.email
        });
        const { timer, startTimer } = useResendTimer(root);
        const { errors, serverErrors, validate } = useValidation();
        const showUpdateEmailForm = ref(false);
        const submittedConfirmationEmail = ref('');
        const handleEmailUpdate = async (emailConfirmationForm) => {
            const valid = validate(emailConfirmationForm);
            if (!valid) {
                return;
            }
            const email = emailConfirmationForm.$model.email.toLowerCase();
            if (submittedConfirmationEmail.value === email) {
                errors.fields.email = validationRules.same;
                return;
            }
            try {
                const updatedUserData = {
                    ...props.signUpData,
                    email
                };
                await globalThis.$store.dispatch('authentication/signUpEmail', updatedUserData);
                submittedConfirmationEmail.value = email;
                showUpdateEmailForm.value = false;
                startTimer();
            }
            catch (error) {
                validate(emailConfirmationForm, prefixError('email', error));
            }
        };
        const resendVerificationLink = async () => {
            await globalThis.$store.dispatch('authentication/resendVerification', {
                method: 'email',
                accountId: props.signUpData.accountId
            });
            showUpdateEmailForm.value = false;
            startTimer();
        };
        const toggleUpdateEmail = () => (showUpdateEmailForm.value = !showUpdateEmailForm.value);
        watch(() => props.signUpData, () => {
            if (!props.signUpData.email) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            }
            else {
                submittedConfirmationEmail.value = props.signUpData.email;
            }
        }, { immediate: true });
        return {
            emailConfirmationForm,
            errors,
            showUpdateEmailForm,
            serverErrors,
            submittedConfirmationEmail,
            timer,
            handleEmailUpdate,
            resendVerificationLink,
            timeZero,
            toggleUpdateEmail
        };
    }
});
