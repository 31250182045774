import { defineComponent, ref, onMounted, onUnmounted, reactive, computed } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { email, required, helpers } from 'vuelidate/lib/validators';
import { useGetSocialNetworkAuthUrlQuery, UserRoleState } from '@/generated-types/graphql.types';
import { toKebabCase } from '@/util/transform';
import { AuthPopups, SignUpSources, SocialProviders, AUTH_SESSION_STORAGE_KEYS, AuthFlow } from '@/Authentication/authentication.const';
import ChangeRole from '@/Authentication/components/ChangeRole.vue';
import Info from '@/Authentication/components/Info.vue';
import PhoneNumber from '@/uikit/PhoneNumber';
import Tip from '@/uikit/Tip.vue';
import { prefixError, server, serverValidationMixin, useValidation } from '@/util/validation';
import InfoBlock from '@/shared/components/InfoBlock.vue';
import i18n from '@/i18n/i18n-setup';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useMeta } from '@/shared/composables/useMeta';
// Allows only alphabetical symbols (with support of acute accents/umlauts/etc.) and hyphens
const alphabetAndDashOnlyValidator = helpers.regex('alphabetAndDashOnly', /^[a-zA-ZÀ-ÿ- ]*$/i);
export default defineComponent({
    components: {
        ChangeRole,
        Info,
        PhoneNumber,
        InfoBlock,
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        signUpForm: {
            firstName: {
                alphabetAndDashOnlyValidator,
                required
            },
            lastName: {
                alphabetAndDashOnlyValidator,
                required
            },
            email: {
                required,
                email,
                _email: server('_email')
            },
            phone: {
                formatted_number: {
                    required,
                    phone: server('phone')
                }
            },
            isSubscribed: {
                required
            }
        }
    },
    setup(_, context) {
        const { root, emit } = context;
        const signUpForm = ref({
            firstName: '',
            lastName: '',
            email: '',
            phone: {},
            isSubscribed: true
        });
        const { errors, validate, serverErrors } = useValidation();
        const accountRole = ref(null);
        const signUpWithEmailForm = ref(false);
        const socialNetworkAuthQueryVariables = reactive({
            socialNetwork: '',
            authFlow: AuthFlow.SIGNUP
        });
        const socialNetworkAuthQueryOptions = reactive({
            enabled: false,
            fetchPolicy: 'network-only'
        });
        const oauthCode = ref(null);
        const query = root.$route.query;
        oauthCode.value = query.code;
        const { refetch, onResult } = useGetSocialNetworkAuthUrlQuery(socialNetworkAuthQueryVariables, socialNetworkAuthQueryOptions // query is disabled by default
        );
        const signUpSource = computed(() => query.source);
        if (signUpSource.value === SignUpSources.BECOME_VENDOR_PAGE) {
            accountRole.value = UserRoleState.Vendor;
            signUpWithEmailForm.value = false;
        }
        onMounted(async () => {
            if (process.browser) {
                const role = window.sessionStorage.getItem(AUTH_SESSION_STORAGE_KEYS.ACTION_FLOW_ROLE_TYPE);
                window.sessionStorage.removeItem(AUTH_SESSION_STORAGE_KEYS.ACTION_FLOW_ROLE_TYPE);
                if (role) {
                    accountRole.value = role;
                }
            }
            if (oauthCode.value) {
                await handleSocialSignUp(oauthCode.value);
            }
            useMeta({
                noIndex: true
            });
        });
        onUnmounted(() => {
            useMeta({
                noIndex: false
            });
        });
        const selectAccountRole = (role) => {
            accountRole.value = role;
            signUpWithEmailForm.value = false;
        };
        const getOAuthLink = async (socialProvider) => {
            try {
                // enable useGetSocialNetworkAuthUrlQuery
                socialNetworkAuthQueryOptions.enabled = true;
                socialNetworkAuthQueryVariables.socialNetwork = socialProvider;
                refetch();
                onResult(queryResult => {
                    const authLink = queryResult?.data?.get_social_network_authorization_url
                        ?.authorization_url;
                    localStorage.setItem('socialNetworkProviderName', socialProvider);
                    if (accountRole.value) {
                        localStorage.setItem('socialNetworkSelectedAccountRole', accountRole.value.toString());
                    }
                    const locale = i18n.locale
                        ? i18n.locale.toUpperCase()
                        : i18n.fallbackLocale?.toString().toUpperCase();
                    localStorage.setItem('signupLocale', locale);
                    if (authLink)
                        window.location.href = authLink;
                });
            }
            catch (error) {
                console.log(error, 'Social login error');
            }
        };
        const handleSocialSignUp = async (code) => {
            try {
                const userData = await globalThis.$store.dispatch('authentication/socialAuth', {
                    socialProvider: localStorage.getItem('socialNetworkProviderName'),
                    accessToken: code,
                    authFlow: AuthFlow.SIGNUP,
                    accountRole: localStorage.getItem('socialNetworkSelectedAccountRole')
                });
                localStorage.removeItem('socialNetworkProviderName');
                localStorage.removeItem('socialNetworkSelectedAccountRole');
                if (userData?.is_created) {
                    if (userData?.account_state === UserRoleState.Vendor) {
                        globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                        return;
                    }
                    globalThis.$router.replace({
                        query: {
                            ...root.$route.query,
                            authPopup: AuthPopups.COMPLETE_ACCOUNT
                        }
                    });
                }
                else {
                    globalThis.$router.replace({
                        query: {
                            ...root.$route.query,
                            authPopup: undefined
                        }
                    });
                }
            }
            catch (error) {
                console.log(error, 'handleSocialSignUp error');
            }
        };
        const toggleSignUpWihEmail = () => {
            signUpWithEmailForm.value = true;
        };
        const handleSignUp = async (sigUpForm) => {
            const userData = {
                ...toKebabCase(sigUpForm.$model),
                locale: globalThis.$i18n.locale.toUpperCase(),
                account_state: accountRole.value,
                email: sigUpForm.$model.email.toLowerCase()
            };
            const valid = validate(sigUpForm);
            if (!valid) {
                return;
            }
            try {
                const { account_id } = await globalThis.$store.dispatch('authentication/signUpEmail', userData);
                emit('submit', {
                    ...userData,
                    accountId: account_id,
                    phone: userData.phone.formatted_number
                });
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: AuthPopups.EMAIL_CONFIRMATION
                    }
                });
                return;
            }
            catch (error) {
                validate(sigUpForm, prefixError('email', error));
            }
        };
        return {
            accountRole,
            errors,
            serverErrors,
            signUpForm,
            signUpWithEmailForm,
            AuthPopups,
            SocialProviders,
            UserRoleState,
            getOAuthLink,
            handleSignUp,
            selectAccountRole,
            toggleSignUpWihEmail
        };
    }
});
