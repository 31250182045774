import { computed, defineComponent, reactive, watch } from '@vue/composition-api';
import { useUnreadNotificationsSubscription } from '@/generated-types/graphql.types';
export default defineComponent({
    setup(_, context) {
        const { emit } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const variables = reactive({ accountId: user.value?.account_id });
        watch(() => user.value, () => {
            variables.accountId = user.value?.account_id;
        }, { immediate: true });
        const { result } = useUnreadNotificationsSubscription(variables, () => ({
            enabled: !!variables.accountId
        }));
        const notificationsCounter = computed(() => result?.value?.unread_notifications?.count);
        watch(() => notificationsCounter.value, () => {
            emit('has-new-notifications', notificationsCounter.value);
        }, { immediate: true });
        return {
            notificationsCounter
        };
    }
});
