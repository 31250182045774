import HeaderBlock from '@/layouts/Header.vue';
import FooterBlock from '@/shared/modules/footer/Footer.vue';
import { defineComponent } from '@vue/composition-api';
import AuthenticationPopupManager from '@/Authentication/AuthenticationPopupManager.vue';
export default defineComponent({
    components: {
        HeaderBlock,
        FooterBlock,
        AuthenticationPopupManager
    }
});
