import { defineComponent } from '@vue/composition-api';
import { AccountState } from '@/generated-types/graphql.types';
import { redirectUserToPrev } from '@/Authentication/utils/user';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        verifiedEmail: {
            type: String,
            default: ''
        },
        user: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const continueAfterConfirmation = () => {
            if (props.user?.account_state === AccountState.Vendor &&
                !props.user?.organisation_id) {
                globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                return;
            }
            if (props.user?.account_state === AccountState.Booker) {
                redirectUserToPrev();
                return;
            }
            globalThis.$router.replace({ query: { authPopup: undefined } });
        };
        return {
            continueAfterConfirmation
        };
    }
});
