import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'BurgerMenu',
    props: {
        notificationsCounter: {
            type: Number,
            default: 0
        },
        chatMessagesCounter: {
            type: Number,
            default: 0
        },
        onFullScreen: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        return {};
    }
});
