import { defineComponent, watch } from '@vue/composition-api';
import { useChatUnread } from '@/shared/composables/useChatUnread';
export default defineComponent({
    props: {
        channelId: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { emit } = context;
        const { unread, channelUnread } = useChatUnread(props.channelId);
        watch(() => unread.value, () => {
            emit('has-new-messages', unread.value);
        }, { immediate: true });
        return {
            channelUnread,
            unread
        };
    }
});
