import { defineComponent, computed, watch } from '@vue/composition-api';
import Access from '@/components/Access.vue';
import ListItem from '@/uikit/ListItem.vue';
import { AdminRoutes } from '@/Admin/admin.const';
import { BookingRoutes } from '@/Booking/booking.const';
import { ChatRoutes } from '@/Chat/chat.const';
import { NotificationsRoutes } from '@/Notifications/notifications.const';
import { UserRoleState } from '@/generated-types/graphql.types';
import { ServicesRoutes } from '@/Services/services.const';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import vClickOutside from 'v-click-outside';
import { FavoritesRoutes } from '@/Favorites/favorites.const';
import { HowToRoutes } from '@/HowTo/howto.const';
export default defineComponent({
    components: {
        Access,
        ListItem
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        notificationsCounter: {
            type: Number,
            default: 0
        },
        chatMessagesCounter: {
            type: Number,
            default: 0
        },
        onFullScreen: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const { emit } = context;
        const close = () => emit('input', false);
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const links = computed(() => {
            return [
                {
                    external: false,
                    role: 'admin',
                    iconClass: 'fa-solid fa-screwdriver-wrench',
                    to: AdminRoutes.ADMIN,
                    title: 'header.link_label_admin',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: null,
                    iconClass: 'fa-solid fa-user',
                    to: 'profile/account',
                    title: 'header.link_label_account',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: true
                },
                {
                    external: false,
                    role: null,
                    iconClass: 'fa-solid fa-home',
                    to: '',
                    title: 'header.home',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: true
                },
                {
                    external: false,
                    role: UserRoleState.Vendor,
                    iconClass: 'fa-solid fa-bell',
                    to: NotificationsRoutes.NOTIFICATIONS,
                    title: 'header.link_label_notifications',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: true,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Booker,
                    iconClass: 'fa-solid fa-bell',
                    to: NotificationsRoutes.NOTIFICATIONS,
                    title: 'header.link_label_notifications',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: true,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Vendor,
                    iconClass: 'fa-solid fa-comment',
                    to: ChatRoutes.CHAT,
                    title: 'header.link_label_messages',
                    hasRedDotOnMessages: true,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Booker,
                    iconClass: 'fa-solid fa-comment',
                    to: ChatRoutes.CHAT,
                    title: 'header.link_label_messages',
                    hasRedDotOnMessages: true,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Vendor,
                    iconClass: 'fa-solid fa-list',
                    to: `${BookingRoutes.BOOKINGS}/${BookingRoutes.ALL}`,
                    title: 'header.link_label_bookings',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Booker,
                    iconClass: 'fa-solid fa-list',
                    to: `${BookingRoutes.BOOKINGS}/${BookingRoutes.ALL}`,
                    title: 'header.link_label_bookings',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Booker,
                    iconClass: 'fa-solid fa-heart',
                    to: FavoritesRoutes.FAVORITES,
                    title: 'header.link_label_favorites',
                    hasRedDotOnMessages: true,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Vendor,
                    iconClass: 'fa-solid fa-bell-concierge',
                    to: ServicesRoutes.SERVICES,
                    title: 'header.link_label_services',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Vendor,
                    iconClass: 'fa-solid fa-building',
                    to: 'vendor/spaces',
                    title: 'header.link_label_spaces',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Vendor,
                    iconClass: 'fa-solid fa-chart-simple',
                    to: `${BookingRoutes.BOOKINGS}/${BookingRoutes.VENDOR}`,
                    title: 'header.link_label_dashboard',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: UserRoleState.Vendor,
                    iconClass: 'fa-solid fa-heart',
                    to: FavoritesRoutes.FAVORITES,
                    title: 'header.link_label_favorites',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: !props.onFullScreen
                },
                {
                    external: false,
                    role: null,
                    iconClass: 'fa-solid fa-lightbulb',
                    to: HelpdeskRoutes.Main,
                    title: 'header.faq',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: true
                },
                {
                    external: true,
                    role: null,
                    title: 'header.contact',
                    to: 'https://plugins.crisp.chat/urn:crisp.im:contact-form:0/contact/77c29001-c4c5-42b0-8b09-83170aa93d6f',
                    iconClass: 'fa-solid fa-phone',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: true
                },
                {
                    external: false,
                    role: null,
                    title: 'header.blog',
                    to: 'blog',
                    iconClass: 'fa-solid fa-blog',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: true
                },
                {
                    external: false,
                    role: null,
                    title: 'how_to.title',
                    to: HowToRoutes.HowTo,
                    iconClass: 'fa-solid fa-clipboard-question',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: true
                },
                {
                    external: false,
                    role: null,
                    title: 'header.about_us',
                    to: 'about-us',
                    iconClass: 'fa-solid fa-circle-info',
                    hasRedDotOnMessages: false,
                    hasRedDotOnNotifications: false,
                    visible: true
                }
            ];
        });
        watch(() => props.value, () => {
            if (props.value) {
                document.body.style.overflow = 'hidden';
            }
            else {
                document.body.style.overflow = 'auto';
            }
        }, { immediate: true });
        return {
            close,
            links,
            routes: {
                booking: BookingRoutes,
                chat: ChatRoutes,
                notifications: NotificationsRoutes,
                account: {
                    ACCOUNT: '/account'
                }
            },
            logout: () => globalThis.$store.dispatch('authentication/logout'),
            user,
            UserRoleState,
            useGetLocalizedPath
        };
    }
});
