import { render, staticRenderFns } from "./ChangeRole.vue?vue&type=template&id=0fd670fc&scoped=true&"
import script from "./ChangeRole.vue?vue&type=script&lang=ts&"
export * from "./ChangeRole.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeRole.vue?vue&type=style&index=0&id=0fd670fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd670fc",
  null
  
)

export default component.exports