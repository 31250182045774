export const redirectUserToPrev = (noRouterUsage = false) => {
    // Redirect guests to the previous page they were on when not logged in
    const loginRedirectUrl = localStorage.getItem('loginRedirectUrl');
    if (loginRedirectUrl) {
        noRouterUsage
            ? (window.location.href = `${window.location.origin}/${loginRedirectUrl}`)
            : globalThis.$router.push(loginRedirectUrl);
    }
    else {
        globalThis.$router.replace({ query: { authPopup: undefined } });
    }
};
