import { defineComponent, ref, watch } from '@vue/composition-api';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { server, serverValidationMixin, useValidation, validationRules } from '@/util/validation';
import { useResendTimer } from '@/util/hooks';
import Tip from '@/uikit/Tip.vue';
import PhoneNumber from '@/uikit/PhoneNumber';
import { UserRoleState } from '@/generated-types/graphql.types';
import { timeZero } from '@/util/filters';
import { AuthPopups } from '@/Authentication/authentication.const';
import { toCamelCase } from '@/util/transform';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        PhoneNumber,
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        phoneVerificationForm: {
            phone: {
                formatted_number: {
                    required,
                    phone: server('phone')
                }
            }
        },
        verificationCode: {
            code: {
                required,
                code: server('code')
            }
        }
    },
    props: {
        phoneData: {
            type: Object,
            default: () => { }
        },
        modeType: {
            type: String,
            default: 'confirmation'
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const phoneVerificationForm = ref({
            phone: {
                formatted_number: props.phoneData.val
            }
        });
        const verificationCode = ref({
            code: ''
        });
        const { timer, startTimer } = useResendTimer(root);
        const showUpdatePhone = ref(false);
        const submittedPhone = ref('');
        const { errors, serverErrors, validate } = useValidation();
        const handlePhoneCode = async (codeForm) => {
            try {
                const valid = validate(codeForm);
                if (!valid) {
                    return;
                }
                const data = await globalThis.$store.dispatch(props.modeType === 'confirmation'
                    ? 'authentication/verifyPhoneCode'
                    : 'authentication/loginByToken', codeForm.$model.code);
                emit('submit', data);
                globalThis.$router.replace({
                    query: props.modeType === 'confirmation'
                        ? {
                            ...root.$route.query,
                            authPopup: AuthPopups.PHONE_CONFIRMED
                        }
                        : { authPopup: undefined }
                });
                if (props.modeType === 'login' &&
                    data.account_state === UserRoleState.Vendor &&
                    !data.organisation_id) {
                    globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                }
            }
            catch (error) {
                validate(codeForm, error);
            }
        };
        const handlePhoneUpdateSubmit = async (phoneForm) => {
            const valid = validate(phoneForm);
            if (!valid) {
                return;
            }
            if (phoneForm.$model.phone.formatted_number === submittedPhone.value) {
                errors.fields.phone = validationRules.same;
                return;
            }
            try {
                props.modeType === 'confirmation'
                    ? await mutate({
                        profile: {
                            phone: toCamelCase(phoneForm.$model.phone)
                        }
                    })
                    : await globalThis.$store.dispatch('authentication/login', phoneForm.$model.phone);
                submittedPhone.value = phoneForm.$model.phone.formatted_number;
                showUpdatePhone.value = false;
                startTimer();
            }
            catch (error) {
                validate(phoneForm, error);
            }
        };
        const resendPhoneLogin = async (phoneForm) => {
            showUpdatePhone.value = false;
            await globalThis.$store.dispatch(props.modeType === 'confirmation'
                ? {
                    method: 'phone',
                    accountId: props.accountData.accountId
                }
                : 'authentication/login', phoneForm.$model.phone.formatted_number);
            startTimer();
        };
        const toggleChangePhoneForm = () => (showUpdatePhone.value = !showUpdatePhone.value);
        watch(() => props.phoneData, () => {
            if (!props.phoneData.val) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            }
            else {
                submittedPhone.value = props.phoneData.val;
            }
        }, { immediate: true });
        return {
            errors,
            phoneVerificationForm,
            serverErrors,
            showUpdatePhone,
            submittedPhone,
            timer,
            verificationCode,
            handlePhoneUpdateSubmit,
            handlePhoneCode,
            resendPhoneLogin,
            timeZero,
            toggleChangePhoneForm
        };
    }
});
