import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        accountRole: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        return {
            roleNumber: computed(() => +(props.accountRole === 'VENDOR'))
        };
    }
});
