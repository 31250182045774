import { defineComponent } from '@vue/composition-api';
import { redirectUserToPrev } from '@/Authentication/utils/user';
export default defineComponent({
    props: {
        verifiedPhone: {
            type: String,
            default: ''
        }
    },
    setup(_, context) {
        const { root } = context;
        const continueAfterConfirmation = () => {
            globalThis.$router.replace({
                query: { ...root.$route.query, authPopup: undefined }
            });
            redirectUserToPrev(true);
        };
        return {
            continueAfterConfirmation
        };
    }
});
