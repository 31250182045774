import { defineComponent, ref, watch } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { prefixError, server, serverValidationMixin, useValidation, validationRules } from '@/util/validation';
import Tip from '@/uikit/Tip.vue';
import { useResendTimer } from '@/util/hooks';
import { timeZero } from '@/util/filters';
export default defineComponent({
    components: {
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        emailVerification: {
            email: {
                required,
                email,
                _email: server('_email')
            }
        }
    },
    props: {
        loginEmail: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root } = context;
        const emailVerification = ref({
            email: props.loginEmail.val
        });
        const { timer, startTimer } = useResendTimer(root);
        const { errors, serverErrors, validate } = useValidation();
        const submittedLoginEmail = ref('');
        const showEmailUpdateForm = ref(false);
        const handleEmailChangeSubmit = async (emailVerification) => {
            const email = emailVerification.$model.email.toLowerCase();
            const valid = validate(emailVerification);
            if (!valid) {
                return;
            }
            if (submittedLoginEmail.value === email) {
                errors.fields.email = validationRules.same;
                return;
            }
            try {
                await globalThis.$store.dispatch('authentication/login', email);
                showEmailUpdateForm.value = false;
                submittedLoginEmail.value = email;
                startTimer();
            }
            catch (error) {
                validate(emailVerification, prefixError('email', error));
            }
        };
        const resendEmailLogin = async (emailVerification) => {
            const email = emailVerification.$model.email.toLowerCase();
            await globalThis.$store.dispatch('authentication/login', email);
            // restarting the timer
            startTimer();
            showEmailUpdateForm.value = false;
        };
        const toggleEmailChangeForm = () => (showEmailUpdateForm.value = !showEmailUpdateForm.value);
        watch(() => props.loginEmail, () => {
            if (!props.loginEmail.val) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            }
            else {
                submittedLoginEmail.value = props.loginEmail.val;
            }
        }, { immediate: true });
        return {
            emailVerification,
            errors,
            serverErrors,
            showEmailUpdateForm,
            submittedLoginEmail,
            timer,
            handleEmailChangeSubmit,
            resendEmailLogin,
            timeZero,
            toggleEmailChangeForm
        };
    }
});
