import { defineComponent } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
export default defineComponent({
    setup() {
        return {
            HelpdeskRoutes,
            useGetLocalizedPath
        };
    }
});
