import { computed, ref } from '@vue/composition-api';
import { useChatListen } from '@/shared/composables/useChatListen';
export const useChatUnread = (channelId) => {
    const channelUnread = ref(0);
    const unread = ref(0);
    useChatListen((event) => {
        if (event.total_unread_count !== undefined) {
            unread.value = event.total_unread_count;
            if (channelId && channelId === event.channel_id) {
                channelUnread.value = event.unread_channels;
            }
        }
        if (event.me) {
            unread.value = event.me.total_unread_count;
            if (channelId && channelId === event.channel_id) {
                channelUnread.value = event.me.unread_channels;
            }
        }
    });
    return {
        unread: computed(() => unread.value),
        channelUnread: computed(() => channelUnread.value)
    };
};
