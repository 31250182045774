import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { computed, defineComponent } from '@vue/composition-api';
import ListItem from '@/uikit/ListItem.vue';
import { AccountState } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        ListItem
    },
    setup() {
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const logout = async () => {
            globalThis.$store.dispatch('authentication/logout');
        };
        return {
            user,
            AccountState,
            ROUTE_LIST_NAMES,
            logout,
            useGetLocalizedPath
        };
    }
});
