var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ container: true, 'without-header': _vm.$route.meta.hideHeader }},[_c('HeaderBlock'),_c('div',{class:{
      'main-wrapper': !_vm.$route.meta.noFrame,
      'mobile-full-width': _vm.$route.meta.mobileFullWidth,
      'container-max-width': _vm.$route.meta.maxWidthContainer,
      'full-width': _vm.$route.meta.fullWidth,
      'no-padding': _vm.$route.meta.noPadding,
      'full-height': _vm.$route.meta.fullHeight,
      center: _vm.$route.meta.center
    }},[_c('router-view')],1),(!_vm.$route.meta.noFooter)?_c('FooterBlock'):_vm._e(),_c('AuthenticationPopupManager')],1)}
var staticRenderFns = []

export { render, staticRenderFns }