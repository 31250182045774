import { computed, ref, watch } from '@vue/composition-api';
import EventBus from '@/shared/services/eventBus';
export function useAvatarManager(root) {
    /**
     * Avatar image URL
     */
    const avatarImageUrl = ref('');
    const user = computed(() => globalThis.$store.getters['$_app/user']);
    watch(() => user.value, () => {
        if (user.value && user.value.avatar_image) {
            avatarImageUrl.value = user.value.avatar_image;
        }
    }, { immediate: true });
    /**
     * Replaces the avatar image with the specified image URL
     */
    function replaceAvatar(imageUrl) {
        avatarImageUrl.value = imageUrl;
    }
    const $this = {
        avatarImageUrl,
        replaceAvatar,
        unsubscribeFromEvents: null,
        unsubscribeFromActions: null,
        deconstruct() {
            // Unsubscribes from events
            if (this.unsubscribeFromEvents) {
                this.unsubscribeFromEvents();
            }
            // Unsubscribes from actions
            if (this.unsubscribeFromActions) {
                this.unsubscribeFromActions();
            }
        }
    };
    useEvents($this);
    return $this;
}
function useEvents($this) {
    /**
     * Avatar image was updated in the Account Settings
     */
    function _avatarUpdated(imageUrl) {
        $this.replaceAvatar(imageUrl);
    }
    // Subscribes to events
    const eventsMapping = {
        avatarUpdated: _avatarUpdated
    };
    Object.entries(eventsMapping).forEach(([event, handler]) => EventBus.$on(event, handler));
    $this.unsubscribeFromEvents = () => Object.keys(eventsMapping).forEach(event => EventBus.$off(event));
}
