import { defineComponent } from '@vue/composition-api';
import storyblok from '@/mixins/storyblok';
export default defineComponent({
    mixins: [storyblok],
    setup() {
        return {
            slug: 'blocks/footer'
        };
    },
    computed: {
        footerBlock() {
            if (this.story.content && this.story.content.body) {
                return this.story.content.body.find(item => item.component === 'footer');
            }
            return null;
        }
    },
    mounted() {
        window.addEventListener('load', () => {
            // run after everything is in-place in the DOM
            // Programmatically Add target="_blank" and ref="noreferrer" to the navigiation block items with
            // `main-footer-privacy` class as those items are rendered by the Storyblok and there is no observable way
            // to add custom properties to the HTML elements in the Storyblok dashboard
            this.$el
                .querySelectorAll('div.main-footer-privacy a')
                .forEach(function (el) {
                el.target = '_blank';
                el.rel = 'noreferrer';
                el.rel = 'noopener';
            });
            this.$el
                .querySelectorAll('.social-media > .storyblok-nav-item > a')
                .forEach(function (el) {
                el.target = '_blank';
                el.rel = 'noreferrer';
                el.rel = 'noopener';
            });
        });
    }
});
