import { defineComponent, ref, onMounted, onUnmounted, reactive } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { AuthPopups, SocialProviders, LoginMethods, AUTH_SESSION_STORAGE_KEYS, AuthFlow } from '@/Authentication/authentication.const';
import PhoneNumber from '@/uikit/PhoneNumber';
import Tip from '@/uikit/Tip.vue';
import { isValid, prefixError, server, serverValidationMixin, useValidation } from '@/util/validation';
import { UserRoleState, useUpdateUserStateMutation, useGetSocialNetworkAuthUrlQuery } from '@/generated-types/graphql.types';
import { withAuthMutationHook } from '@/util/hooks';
import i18n from '@/i18n/i18n-setup';
import { redirectUserToPrev } from '@/Authentication/utils/user';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { BookingRoutes } from '@/Booking/booking.const';
import { useMeta } from '@/shared/composables/useMeta';
const VerificationPopups = {
    [LoginMethods.PHONE]: AuthPopups.PHONE_LOGIN,
    [LoginMethods.EMAIL]: AuthPopups.EMAIL_LOGIN
};
const updateProfile = withAuthMutationHook(useUpdateUserStateMutation);
export default defineComponent({
    components: {
        PhoneNumber,
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        loginForm: {
            phone: {
                formatted_number: {
                    required,
                    phone: server('phone')
                }
            },
            email: {
                required,
                email,
                _email: server('_email')
            }
        }
    },
    setup(_, context) {
        const { root, emit } = context;
        const loginForm = ref({
            phone: {},
            email: ''
        });
        const { mutate } = updateProfile({ clientId: 'legacy' });
        const loginMethod = ref(null);
        const socialNetworkAuthQueryVariables = reactive({
            socialNetwork: '',
            authFlow: AuthFlow.LOGIN
        });
        const socialNetworkAuthQueryOptions = reactive({
            enabled: false,
            fetchPolicy: 'network-only'
        });
        const oauthCode = ref('');
        const query = root.$route.query;
        const oAuthCode = query.code;
        oauthCode.value =
            oAuthCode && typeof oAuthCode === 'string' ? oAuthCode : '';
        const { errors, validate, reset, serverErrors } = useValidation();
        const { refetch, onResult } = useGetSocialNetworkAuthUrlQuery(socialNetworkAuthQueryVariables, socialNetworkAuthQueryOptions);
        const handleSocialLogin = async (code) => {
            try {
                const userData = await globalThis.$store.dispatch('authentication/socialAuth', {
                    socialProvider: localStorage.getItem('socialNetworkProviderName'),
                    accessToken: code,
                    authFlow: AuthFlow.LOGIN
                });
                localStorage.removeItem('socialNetworkProviderName');
                if (userData.account_state === UserRoleState.Vendor) {
                    if (!userData.organisation_id) {
                        globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                    }
                    else {
                        globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${BookingRoutes.VENDOR}`));
                    }
                    return;
                }
                if (userData?.is_created) {
                    if (process.browser) {
                        const role = window.sessionStorage.getItem(AUTH_SESSION_STORAGE_KEYS.ACTION_FLOW_ROLE_TYPE);
                        window.sessionStorage.removeItem(AUTH_SESSION_STORAGE_KEYS.ACTION_FLOW_ROLE_TYPE);
                        if (role) {
                            await mutate({ profile: { accountState: role } });
                        }
                        globalThis.$router.replace({
                            query: {
                                ...root.$route.query,
                                authPopup: role
                                    ? AuthPopups.COMPLETE_ACCOUNT
                                    : AuthPopups.SELECT_ROLE
                            }
                        });
                    }
                }
                else {
                    redirectUserToPrev(true);
                }
            }
            catch (error) {
                console.log(error, 'Social login error');
            }
        };
        const getOAuthLink = async (socialProvider) => {
            try {
                // enable useGetSocialNetworkAuthUrlQuery
                socialNetworkAuthQueryOptions.enabled = true;
                socialNetworkAuthQueryVariables.socialNetwork = socialProvider;
                refetch(socialNetworkAuthQueryVariables);
                onResult(queryResult => {
                    const authLink = queryResult?.data?.get_social_network_authorization_url
                        ?.authorization_url;
                    localStorage.setItem('socialNetworkProviderName', socialProvider);
                    const locale = i18n.locale
                        ? i18n.locale.toUpperCase()
                        : i18n.fallbackLocale?.toString().toUpperCase();
                    localStorage.setItem('signupLocale', locale);
                    if (authLink)
                        window.location.href = authLink;
                });
            }
            catch (error) {
                console.log(error, 'Social login error');
            }
        };
        const handleLoginForm = async (loginForm) => {
            try {
                let val = '';
                let verifyPopupType = '';
                const { $model: { phone, email } } = loginForm;
                validate(loginForm);
                if (loginMethod.value) {
                    if (!isValid(loginForm[loginMethod.value]))
                        return;
                    verifyPopupType = VerificationPopups[loginMethod.value];
                    val =
                        verifyPopupType === AuthPopups.PHONE_LOGIN
                            ? phone
                            : email.toLowerCase();
                }
                const { account_id } = await globalThis.$store.dispatch('authentication/login', val);
                emit('submit', {
                    val: verifyPopupType === AuthPopups.PHONE_LOGIN
                        ? phone.formatted_number
                        : email.toLowerCase(),
                    accountId: account_id,
                    loginMethod: loginMethod.value
                });
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: verifyPopupType
                    }
                });
            }
            catch (error) {
                validate(loginForm, prefixError('email', error));
            }
        };
        const toggleLoginMethod = (method, loginForm) => {
            loginMethod.value = method;
            if (loginForm) {
                loginForm.$model.email = '';
                loginForm.$model.phone = '';
                loginForm?.$reset();
                reset();
            }
        };
        onMounted(async () => {
            if (oauthCode.value) {
                await handleSocialLogin(oauthCode.value);
            }
            useMeta({
                noIndex: true
            });
        });
        onUnmounted(() => {
            useMeta({
                noIndex: false
            });
        });
        return {
            errors,
            loginForm,
            loginMethod,
            serverErrors,
            AuthPopups,
            LoginMethods,
            SocialProviders,
            UserRoleState,
            getOAuthLink,
            handleLoginForm,
            toggleLoginMethod
        };
    }
});
