import { onUnmounted, ref } from '@vue/composition-api';
import { useChatEffect } from '@/shared/composables/useChatEffect';
export const useChatListen = (handler) => {
    const values = ref(null);
    const listener = (...args) => {
        values.value = handler(...args);
    };
    const chat = useChatEffect((chat) => {
        chat.on((event) => listener(event, chat));
        return chat;
    });
    onUnmounted(() => {
        chat.value?.off(listener);
    });
    return values;
};
