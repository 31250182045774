import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    props: {
        image: {
            type: String,
            default: ''
        },
        height: {
            type: Number,
            default: 0
        },
        width: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const transformedAvatarImage = computed(() => {
            return props.width && props.height
                ? `${props.image}?tr=w-${props.width.toString()},h-${props.height.toString()}`
                : props.image;
        });
        return {
            transformedAvatarImage
        };
    }
});
