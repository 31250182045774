import { defineComponent } from '@vue/composition-api';
import { UserRoleState, useUpdateUserStateMutation } from '@/generated-types/graphql.types';
import { AuthPopups } from '@/Authentication/authentication.const';
import { withAuthMutationHook } from '@/util/hooks';
import { useGetLocalizedPath } from '@/util/globalHelpers';
const updateProfile = withAuthMutationHook(useUpdateUserStateMutation);
export default defineComponent({
    setup(_, context) {
        const { root } = context;
        const { mutate } = updateProfile({ clientId: 'legacy' });
        const handleRoleSelect = async (role) => {
            await mutate({ profile: { accountState: role } });
            if (role === UserRoleState.Vendor) {
                globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                return;
            }
            globalThis.$router.replace({
                query: {
                    ...root.$route.query,
                    authPopup: AuthPopups.COMPLETE_ACCOUNT
                }
            });
        };
        return {
            UserRoleState,
            handleRoleSelect
        };
    }
});
