import { defineComponent, ref, computed, watch, onMounted, onUnmounted } from '@vue/composition-api';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import findLast from 'lodash/findLast';
import EventBus from '@/shared/services/eventBus';
import BurgerMenu from '@/components/BurgerMenu.vue';
import MenuPopup from '@/components/MenuPopup.vue';
import AccountModal from '@/Account/components/AccountModal.vue';
import DropdownList from '@/shared/components/form/DropdownList.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { BookingRoutes } from '@/Booking/booking.const';
import { ServicesRoutes } from '@/Services/services.const';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import { ChatRoutes } from '@/Chat/chat.const';
import { NotificationsRoutes } from '@/Notifications/notifications.const';
import { FavoritesRoutes } from '@/Favorites/favorites.const';
import { AdminRoutes } from '@/Admin/admin.const';
import { UserRoleState, useUpdateUserStateMutation } from '@/generated-types/graphql.types';
import NotificationsCounterProvider from '@/Notifications/components/NotificationsCounterProvider.vue';
import ChatUnreadProvider from '@/Chat/components/ChatUnreadProvider.vue';
import Logo from '@/components/Logo.vue';
import AvatarViewer from '@/Account/components/AvatarViewer.vue';
import { useAvatarManager } from '@/layouts/avatarManager';
import { withAuthMutationHook } from '@/util/hooks';
import { saveCurrentPath } from '@/util/utils';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import vClickOutside from 'v-click-outside';
const updateProfile = withAuthMutationHook(useUpdateUserStateMutation);
export default defineComponent({
    components: {
        BurgerMenu,
        MenuPopup,
        AccountModal,
        DropdownList,
        NotificationsCounterProvider,
        ChatUnreadProvider,
        Logo,
        AvatarViewer
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    setup(_, context) {
        const { root } = context;
        const breadcrumb = ref(undefined);
        const header = ref(undefined);
        const notificationsCounter = ref(0);
        const chatMessagesCounter = ref(0);
        const languagesArray = computed(() => [
            {
                label: root.$i18n.t('common.languages.dutch').toString(),
                value: 'nl'
            },
            {
                label: root.$i18n.t('common.languages.english').toString(),
                value: 'en'
            }
        ]);
        const onNewNotifications = (event) => {
            notificationsCounter.value = event;
        };
        const onNewMessages = (event) => {
            chatMessagesCounter.value = event;
        };
        watch(() => root.$route, () => {
            breadcrumb.value = findLast(globalThis.$router.currentRoute.matched, route => route.meta?.breadcrumbs)?.meta?.breadcrumbs;
            header.value = findLast(globalThis.$router.currentRoute.matched, route => route.meta?.header)?.meta?.header;
        }, { immediate: true });
        const popupMenuVisible = ref(false);
        const languageListOpen = ref(false);
        const showAccount = ref(false);
        const isAdmin = ref(false);
        const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
        const avatarManager = ref(null);
        const avatarImageUrl = computed(() => avatarManager.value?.avatarImageUrl);
        const mq = computed(() => root.$mq);
        const { mutate } = updateProfile({ clientId: 'legacy' });
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        EventBus.$on('responsePayload', response => {
            const errMsg = response.data
                ? `Status code: ${response.data.status_code}. Details: ${response.data.details || response.data.reason}`
                : response;
            addToast({
                text: errMsg,
                type: 'danger',
                dismissAfter: 7000
            });
        });
        // avatarImageUrl is already reactive, so it will be updated automatically after receiving the callback
        EventBus.$on('avatarUpdated', () => { });
        onMounted(() => {
            avatarManager.value = useAvatarManager(root);
        });
        onUnmounted(() => {
            avatarManager.value?.deconstruct();
        });
        const toggleOpenLanguageList = () => {
            languageListOpen.value = !languageListOpen.value;
        };
        const closeLanguageList = () => {
            languageListOpen.value = false;
        };
        const onSelectLanguage = async (locale) => {
            if (globalThis.$i18n.locale !== locale) {
                EventBus.$emit('languageSelected', locale);
                const to = globalThis.$router.resolve({ params: { locale } });
                let params = to.location.path.split('/');
                const languages = ['en', 'nl'];
                if (languages.includes(params[1])) {
                    if (params[1] === 'nl') {
                        params.splice(1, 1);
                    }
                    else {
                        params[1] = locale;
                    }
                }
                else {
                    if (locale === 'nl') {
                        // params.splice(2, 0, locale);
                    }
                    else {
                        params.splice(1, 0, locale);
                    }
                }
                if (params[params.length - 1] === '') {
                    params.pop();
                }
                let path = params.join('/');
                if (!path) {
                    path = '/';
                }
                if (user.value) {
                    await mutate({ profile: { locale: locale.toUpperCase() } });
                    globalThis.$store.dispatch('$_app/refetchUser');
                }
                path += window.location.search;
                globalThis.$router.push({ path });
            }
            languageListOpen.value = false;
        };
        const toggleAccountModal = () => {
            showAccount.value = !showAccount.value;
        };
        const closeAccountModal = () => {
            showAccount.value = false;
        };
        const onLoginClicked = () => {
            saveCurrentPath(root.$route.fullPath.slice(1));
        };
        return {
            avatarImageUrl,
            breadcrumb,
            chatMessagesCounter,
            header,
            isAdmin,
            languageListOpen,
            languagesArray,
            locale: computed(() => globalThis.$i18n.locale.toLowerCase()),
            mq,
            notificationsCounter,
            popupMenuVisible,
            routes: {
                booking: BookingRoutes,
                services: ServicesRoutes,
                chat: ChatRoutes,
                favorites: FavoritesRoutes,
                notification: NotificationsRoutes,
                admin: AdminRoutes
            },
            showAccount,
            user,
            HelpdeskRoutes,
            UserRoleState,
            ROUTE_LIST_NAMES,
            closeAccountModal,
            closeLanguageList,
            onLoginClicked,
            onNewNotifications,
            onNewMessages,
            onSelectLanguage,
            toggleAccountModal,
            toggleOpenLanguageList,
            useGetLocalizedPath
        };
    }
});
