import { ref, watch, computed } from '@vue/composition-api';
import equal from 'lodash/isEqual';
export const useChatEffect = (handler, dependencies) => {
    const chat = computed(() => {
        return globalThis.$store.getters['$_app/newChat'];
    });
    const result = ref(null);
    const watchers = [chat].concat(dependencies ?? []);
    watch(watchers, async ([stream, ...dep] = [], [oldStream, ...oldDep] = []) => {
        if (stream && (stream !== oldStream || !equal(dep, oldDep))) {
            const value = handler(stream, ...dep);
            result.value = value instanceof Promise ? await value : value;
        }
    }, { immediate: true });
    return result;
};
