import { defineComponent, computed, watch, ref } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import Info from '@/Authentication/components/Info.vue';
import { serverValidationMixin, server, useValidation } from '@/util/validation';
import PhoneNumber from '@/uikit/PhoneNumber';
import { useCompleteAccountMutation } from '@/generated-types/graphql.types';
import { withAuthMutationHook } from '@/util/hooks';
import omit from 'lodash/omit';
import { AuthPopups } from '@/Authentication/authentication.const';
import Tip from '@/uikit/Tip.vue';
import { toCamelCase } from '@/util/transform';
const completeAccount = withAuthMutationHook(useCompleteAccountMutation);
export default defineComponent({
    components: {
        PhoneNumber,
        Info,
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        completeAccountForm: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            email: {
                required,
                email,
                _email: server('_email')
            },
            phone: {
                formatted_number: {
                    required,
                    phone: server('phone')
                }
            },
            isSubscribed: {
                required
            }
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const completeAccountForm = ref({
            firstName: props.user.first_name,
            lastName: props.user.last_name,
            email: props.user.email,
            phone: {
                formatted_number: props.user.phone
            },
            isSubscribed: false
        });
        const { errors, serverErrors, validate } = useValidation();
        const { mutate } = completeAccount({ clientId: 'legacy' });
        watch(() => props.user, () => {
            if (props.user && props.user.phone) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            }
        }, { immediate: true });
        const handleCompleteAccount = async (completeAccountForm) => {
            try {
                const valid = validate(completeAccountForm);
                if (!valid) {
                    return;
                }
                await mutate({
                    profile: {
                        ...omit(completeAccountForm.$model, ['email']),
                        phone: toCamelCase(completeAccountForm.$model.phone)
                    }
                });
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
                emit('submit', {
                    phone: completeAccountForm.$model.phone.formatted_number,
                    accountId: props.user.account_id
                });
                globalThis.$router.replace({
                    query: {
                        ...globalThis.$router.query,
                        authPopup: AuthPopups.PHONE_CONFIRMATION
                    }
                });
            }
            catch (error) {
                validate(completeAccountForm, error);
            }
        };
        return {
            completeAccountForm,
            currentSocialProvider: computed(() => {
                if (props.user?.social_networks) {
                    const keys = Object.keys(props.user?.social_networks);
                    return keys[0];
                }
                return null;
            }),
            errors,
            serverErrors,
            handleCompleteAccount
        };
    }
});
